<template>
  <div>
    <b-row>
      <b-col cols="3">
        <!--        <b-avatar size="lg" variant="info" :src="dataDetail.cover_url" square>-->

        <!--        </b-avatar>-->
        <b-card-img :src="dataDetail.cover_url" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col cols="9">
        <b-row>
          <b-col cols="12">
            <h4>{{dataDetail.name}}</h4>
          </b-col>
          <b-col cols="3">
            <p>Tác giả</p>
            <p>Nhà xuất bản</p>
            <p>Chủ đề</p>
            <p>Thể loại</p>
            <p>Ngôn ngữ</p>
          </b-col>
          <b-col cols="9">
            <p>{{dataDetail.authors.map(author => author.name).join(", ")}}</p>
            <p>{{dataDetail.publisher_name}}</p>
            <p>{{dataDetail.categories.map(author => author.name).join(", ")}}</p>
            <p>{{dataDetail.genres.map(author => author.name).join(", ")}}</p>
            <p>{{dataDetail.language_name}}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardImg,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
export default {
  name: 'FormDocument',
  components: {
    BRow,
    BCol,
    BCardImg,
  },
  props: {
    dataSendForm: {
      anPhamId: null,
    },
  },
  data() {
    return {
      dataDetail: {},
    }
  },
  async created() {
    // init data FROM API
    if (this.dataSendForm.anPhamId) {
      console.log(this.dataSendForm.anPhamId)
      const data = await this.getInfoPublication({ anPhamId: this.dataSendForm.anPhamId })
      this.dataDetail = data
    }
  },
  computed: {
    // calculate data from init data
  },
  watch: {
    // listening change data init
  },
  methods: {
    ...mapActions({
      getInfoPublication: 'digitalLibrary/getInfoPublication',
    }),
    // handle even
    async getInitData() {
      // await this.getDocuments()
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style scoped>
</style>
